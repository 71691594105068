import type { CasinoLastBet } from '~/components/LastBets/types'

export function useLastBigBetsCasino(itemsNumber = 9) {
  interface CasinoBetDetails {
    casinoBigBetDetails: CasinoLastBet
  }

  const io = useSocket()

  const { data: bigBets, pending } = useStFetch('/casino/last-big-bets', {
    method: 'post',
    transform: (response) =>
      response.slice(0, itemsNumber) as unknown as CasinoLastBet[],
  })

  function casinoBigBetListener(val: CasinoBetDetails) {
    if (bigBets.value && val) {
      bigBets.value.unshift(val.casinoBigBetDetails)
      bigBets.value.splice(itemsNumber)
    }
  }

  onMounted(() => {
    io.on('lastBetsCasinoBigBetDetails', casinoBigBetListener)
  })

  onBeforeUnmount(() => {
    io.off('lastBetsCasinoBigBetDetails', casinoBigBetListener)
  })

  return { lastBigBets: bigBets, pending }
}
